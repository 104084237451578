$primary-color: #1b1f23;
$secondary-color: #ce0000;
$hero-bkg:#1b1f23;
$featured-image: url(/images/idlmj-bkg.jpg);
$icon-color:$secondary-color;

$page-header-bkg:#FFF;
$page-header:$primary-color;

$toggle-icon-bar:white;
$line-color:#ededed;

$home-search-bkg:#FFF;
$home-search-text:$primary-color;
$home-search-submit-bkg:$secondary-color;
$home-search-submit-txt:#FFF;


/* header and navbar */
body{
  background-color:#eceff4;
  color:#404040;
  font-family:'Lato', sans-serif;
}
header{
  background-color:$primary-color;
}
.navbar{
  margin-bottom:0;
  min-height:50px;
}
.navbar-brand {
  padding: 10px 0;
  img{
    padding-left:15px;
    padding-top:5px;
    max-width:220px;
  }
}

.navbar-nav>li>a {
  padding-top:25px;
  font-size:16px;
  color: #919497;
  text-transform: uppercase;
}
.nav>li>a:focus, .nav>li>a:hover{
  background-color : transparent;
  color: #FFF;
}
.nav .open>a, .nav .open>a:focus, .nav .open>a:hover {
  background-color:$primary-color;
}
.navbar-toggle {
  background-color:$secondary-color;
  margin:18px 15px 0 0;

  .icon-bar {
    background-color: $toggle-icon-bar;
  }
}
.dropdown-menu{
  background-color:#1b1f23;
  li>a{
    color: #919497;
    padding:10px;
    text-transform: uppercase;
  }
}
/* body, main and basic styles */
body{
  color:#666;
  font:16px "Lato", sans-serif;
  line-height: 23px;
  word-wrap: break-word;
}
a{
  color:$primary-color;
}
a:hover, a:active{
  color:#e04590;
}
h1, h2, h3, h4, h5, h6{
  color : $primary-color;
  font-family: 'Raleway', sans-serif;
  font-weight:700;
  letter-spacing:1px;
}
ul{
  list-style: none;
  -webkit-padding-start:0;
}
p {
  margin:0 0 20px;
}
input[type="text"], input[type="email"], input[type="search"], input[type="password"], input[type="tel"], input[type="url"], input[type="date"], textarea{
  padding: 13px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  width: 90%;
  margin: 0 0 20px;
  background-color: #f9f9f9;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-size:18px;
}
input[type="reset"], input[type="button"], input[type="submit"]{
  background-color: $secondary-color;
  border:1px solid $secondary-color;
  text-align: center;
  text-transform: uppercase;
  padding: 10px 30px 10px 30px;
  border-radius: 25px;
  margin: 0 0 10px;
  box-shadow: 0 0 6px #666;
  text-shadow: none;
  font-weight: 400;
  vertical-align: middle;
  cursor: pointer;
  white-space: nowrap;
  font-size: 16px;
  color: #FFF;
}
main {
  padding:30px 0 40px;
}

/*posts*/
.post-excerpt, .post{
  padding: 0 20px 10px;
  margin-bottom: 20px;
  clear:both;
  border-bottom:1px solid $line-color;
  background-color:#FFF;
  border-radius: 10px;
  .meta{
    padding: 5px 0;
    margin-bottom: 5px;
    font-size:12px;
    color:#989898;
    text-transform: uppercase;
  }
  p.title{
    font-family: 'Raleway', sans-serif;
    font-size:26px;
    line-height: 40px;
    margin:20px 0 5px;
    display:inline-block;
    color: $primary-color;
    font-weight:700;
    background-image: url(/images/heart.png);
    background-repeat: no-repeat;
    background-position: left top;
    background-position-y: 3px;
    a{
      color: $primary-color;
      font-weight:700;
      margin-left: 30px;
    }
    a:hover{
      text-decoration: none;
    }
  }
  p {
    max-width:98%;
    line-height:26px;
    margin-bottom:20px;
  }
  .excerpt{
    display:inline-block;
    width:88%;
  }
  a.button {
    background-color: $secondary-color;
    border: 1px solid $secondary-color;
    text-align: center;
    text-transform: uppercase;
    padding: 13px 35px 13px 35px;
    border-radius: 25px;
    margin: 0 0 10px;
    box-shadow: 0 0 6px #666;
    text-shadow: none;
    font-weight: 400;
    vertical-align: middle;
    cursor: pointer;
    white-space: nowrap;
    font-size: 16px;
    color: #FFF;
  }
  a.button:hover{
    text-decoration: none;
    background-color:$primary-color;
  }
}
.pagination {
  display: block;
  min-height: 50px;
  text-align: center;
  margin-bottom:50px;
  a {
    color: #333;
    padding: 5px 10px;
    text-align: center;
    margin: 5px;
    border-radius: 4px;
    display:inline-block;
    background-color: #FFF;
    min-width:35px;
  }
  a:hover {
    color:$secondary-color;
    text-decoration: none;
  }

}

/* sidebar */
aside.widget {
  background-color: #ffffff;
  margin-bottom: 20px;
  -webkit-border-radius: 10x;
  -moz-border-radius: 10px;
  border-radius: 10px;
  h3.widget-title, h3, h2.title {
    background-color: #fafafa;
    color: #121212;
    font-family: sans-serif, Arial, serif;
    font-size: 14px;
    font-style: normal;
    font-variant: normal;
    font-weight: bold;
    line-height: 20px;
    margin: 0;
    padding: 10px 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    -webkit-border-top-left-radius: 10px;
    -moz-border-top-left-radius: 10px;
    border-top-left-radius: 10px;
    -webkit-border-top-right-radius: 10px;
    -moz-border-top-right-radius: 10px;
    border-top-right-radius: 10px;
  }
  .textwidget{
    padding-left:20px;
    padding-bottom:10px;
    h4{
      font-size:13px;
    }
  }
  ul {
    padding:0 20px;
    li:first-child{
      border-top:none;
    }
    li {
      border-top: 1px solid rgba(0, 0, 0, 0.05);
      background-image: url(/images/heart.png) !important;
      background-repeat: no-repeat;
      background-size: 20px;
      background-position: left center;
      padding: 6px 0 10px 3px;
      a {
        color: #999999;
        padding-left:20px;
      }
    }
  }
}
#contentsec{
  background: white;
  padding: 30px;
  border-radius: 10px;
}
#contact{
  padding:20px;
  background-color:#FFF;
  border-radius: 10px;
  #contactform .label{
    display:none;
  }
}
/* footer */
.before-footer{
  background-color:$primary-color;
  color:#999999;
  padding: 20px 0 20px;
  text-align:left;
  margin:0 auto;
  font-size:13px;
  h3{
    color: #FFF;
    font-size: 15px;
    text-transform: uppercase;
  }
  img{
    padding:10px 0 30px;
    max-width:200px;
  }
  a{
    display: block;
    color:#999999;
    padding:5px 0;
  }
  ul li {
    border-top: 1px solid rgba( 255, 255, 255, 0.05 );
    background-image: url(/images/heart.png) !important;
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: left center;
    padding: 6px 0 6px 3px;
    a{
      padding: 0 0 0 20px;
    }
  }
}
footer{
  background-color:$secondary-color;
  padding: 20px 0 20px;
  color:#FFF;
  font-size: 14px;
  line-height: 21px;
  text-align:center;
  .container{
    margin: auto;
    max-width: 100%;
  }
}

/*section specific code */
/*styles for page-header partial*/
section.page-title-wrap {
  background-color: $page-header-bkg !important;
  padding               :20px 0;
  text-align            : center;
  background-attachment : fixed;
  background-position   : center center;
  background-size       : cover;
  margin-top            : -1px;

  h1.page-title {
    font-size: 30px;
    line-height: 37px;
    color: $page-header;
    margin:0 auto;
    text-shadow:1px 1px 2px #888;
    text-transform: uppercase;
  }
}
/* hero */
.home-header-wrap{
  background-image: $featured-image;
  background-repeat:no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
  .header-content-wrap {
    background: rgba( 0,0,0, 0.64 );
    padding: 160px 0 200px;
    h1 {
      text-transform: none;
      text-shadow: 1px 1px 18px #666;
      text-align: center;
      color: #FFF;
      line-height: 65px;
      font-size: 50px;
    }
    h3{
      text-align: center;
      margin:auto;
      color: #f3f3f3;
      padding-top:20px;
    }
    .buttons{
      text-align: center;
      margin-top: 45px;
      .btn{
        text-transform: uppercase;
        display: inline-block !important;
        text-align: center;
        margin: 10px;
        padding: 13px 35px 13px 35px;
        font-size:18px;
        border-color:transparent;
      }
      .red-btn{
        background: $secondary-color;
        box-shadow: 0 0 6px #666;
        border-radius: 25px;
      }
    }
  }
}
@media screen and (max-width:440px) {
  .home-header-wrap{
    .header-content-wrap {
      h1 {
        font-size: 34px;
        line-height:50px;
      }
    }
  }
}
/*home search only needed when including the search-jobs partial*/
section#home-search{
  text-align:center;
  background-color:$home-search-bkg;
  padding: 20px 0 50px;
  h3{
    color:$home-search-text;
    padding-top: 0;
    font-size: 2em;
    text-shadow:1px 1px 2px #888;
    margin-bottom:20px;
    text-transform: uppercase;
  }
  form{
    max-width: 75%;
    text-align: center;
    margin: auto;
    padding:0;
  }
  input[type="text"]{
    width:37%;
    margin-right:1%;
  }
  input[type="submit"]{
    background-color:$home-search-submit-bkg;
    border:1px solid $home-search-submit-bkg;
    color:$home-search-submit-txt;
    border-radius: 25px;
    font-size:16px;
    text-transform: uppercase;
    display:inline-block;
    box-shadow: 0 0 6px #666;
  }
}
@media screen and (max-width:782px) {
  section#home-search form {
    max-width : 90%;
    input[type="text"]{
      width:100%;
    }
  }
}


/*home listings*/
.jobs {
  padding: 0;
  display: flex;
  max-width:1170px;
  margin:0 auto 20px;
  text-align:center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  h3{
    color: #404040;
    padding-bottom: 10px;
    position: relative;
    display: inline-block;
    font-size: 45px;
    text-transform: uppercase;
    text-align:center;
    margin:30px auto;
    line-height: 50px;
  }
  section{
    flex-basis: 98%;
    padding: 1.5% 2.5%;
    margin: 0 0 20px;
    vertical-align: top!important;
    display: inline-block;
    background: #ffffff !important;
    border-radius: 10px;
    text-align: left;
  }
  h2.Title {
    padding-bottom: 10px;
    margin-top:0;
    line-height:24px;
    height:26px;
    overflow: hidden;
    a {
      font-family: 'Raleway', sans-serif;
      color: $primary-color;
      font-size: 18px;
      text-transform: capitalize;
      font-weight:700;
    }
  }
  a.Company{
    display: block;
    color: #404040;
    font-size:14px;
  }
  .TimePosted {
    display:block;
    color:#999;
    font-size:12px;
  }
  .snippet {
    display:block;
    a.More{
      display: block;
      padding: 10px;
      margin-top: 10px;
      background-color: $secondary-color;
      color: #FFFFFF;
      width: 100px;
      text-align: center;
      border-radius: 25px;
    }
  }
}
@media screen and (max-width:782px) {
  .jobs {
    section{
      flex-basis:90%;
      justify-content: center;
      margin: 0 auto 20px;
      padding:5%;
    }
  }
}


