/* header and navbar */
body {
  background-color: #eceff4;
  color: #404040;
  font-family: 'Lato', sans-serif;
}

header {
  background-color: #1b1f23;
}

.navbar {
  margin-bottom: 0;
  min-height: 50px;
}

.navbar-brand {
  padding: 10px 0;
}

.navbar-brand img {
  padding-left: 15px;
  padding-top: 5px;
  max-width: 220px;
}

.navbar-nav > li > a {
  padding-top: 25px;
  font-size: 16px;
  color: #919497;
  text-transform: uppercase;
}

.nav > li > a:focus, .nav > li > a:hover {
  background-color: transparent;
  color: #FFF;
}

.nav .open > a, .nav .open > a:focus, .nav .open > a:hover {
  background-color: #1b1f23;
}

.navbar-toggle {
  background-color: #ce0000;
  margin: 18px 15px 0 0;
}

.navbar-toggle .icon-bar {
  background-color: white;
}

.dropdown-menu {
  background-color: #1b1f23;
}

.dropdown-menu li > a {
  color: #919497;
  padding: 10px;
  text-transform: uppercase;
}

/* body, main and basic styles */
body {
  color: #666;
  font: 16px "Lato", sans-serif;
  line-height: 23px;
  word-wrap: break-word;
}

a {
  color: #1b1f23;
}

a:hover, a:active {
  color: #e04590;
}

h1, h2, h3, h4, h5, h6 {
  color: #1b1f23;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
}

ul {
  list-style: none;
  -webkit-padding-start: 0;
}

p {
  margin: 0 0 20px;
}

input[type="text"], input[type="email"], input[type="search"], input[type="password"], input[type="tel"], input[type="url"], input[type="date"], textarea {
  padding: 13px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  width: 90%;
  margin: 0 0 20px;
  background-color: #f9f9f9;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-size: 18px;
}

input[type="reset"], input[type="button"], input[type="submit"] {
  background-color: #ce0000;
  border: 1px solid #ce0000;
  text-align: center;
  text-transform: uppercase;
  padding: 10px 30px 10px 30px;
  border-radius: 25px;
  margin: 0 0 10px;
  box-shadow: 0 0 6px #666;
  text-shadow: none;
  font-weight: 400;
  vertical-align: middle;
  cursor: pointer;
  white-space: nowrap;
  font-size: 16px;
  color: #FFF;
}

main {
  padding: 30px 0 40px;
}

/*posts*/
.post-excerpt, .post {
  padding: 0 20px 10px;
  margin-bottom: 20px;
  clear: both;
  border-bottom: 1px solid #ededed;
  background-color: #FFF;
  border-radius: 10px;
}

.post-excerpt .meta, .post .meta {
  padding: 5px 0;
  margin-bottom: 5px;
  font-size: 12px;
  color: #989898;
  text-transform: uppercase;
}

.post-excerpt p.title, .post p.title {
  font-family: 'Raleway', sans-serif;
  font-size: 26px;
  line-height: 40px;
  margin: 20px 0 5px;
  display: inline-block;
  color: #1b1f23;
  font-weight: 700;
  background-image: url(/images/heart.png);
  background-repeat: no-repeat;
  background-position: left top;
  background-position-y: 3px;
}

.post-excerpt p.title a, .post p.title a {
  color: #1b1f23;
  font-weight: 700;
  margin-left: 30px;
}

.post-excerpt p.title a:hover, .post p.title a:hover {
  text-decoration: none;
}

.post-excerpt p, .post p {
  max-width: 98%;
  line-height: 26px;
  margin-bottom: 20px;
}

.post-excerpt .excerpt, .post .excerpt {
  display: inline-block;
  width: 88%;
}

.post-excerpt a.button, .post a.button {
  background-color: #ce0000;
  border: 1px solid #ce0000;
  text-align: center;
  text-transform: uppercase;
  padding: 13px 35px 13px 35px;
  border-radius: 25px;
  margin: 0 0 10px;
  box-shadow: 0 0 6px #666;
  text-shadow: none;
  font-weight: 400;
  vertical-align: middle;
  cursor: pointer;
  white-space: nowrap;
  font-size: 16px;
  color: #FFF;
}

.post-excerpt a.button:hover, .post a.button:hover {
  text-decoration: none;
  background-color: #1b1f23;
}

.pagination {
  display: block;
  min-height: 50px;
  text-align: center;
  margin-bottom: 50px;
}

.pagination a {
  color: #333;
  padding: 5px 10px;
  text-align: center;
  margin: 5px;
  border-radius: 4px;
  display: inline-block;
  background-color: #FFF;
  min-width: 35px;
}

.pagination a:hover {
  color: #ce0000;
  text-decoration: none;
}

/* sidebar */
aside.widget {
  background-color: #ffffff;
  margin-bottom: 20px;
  -webkit-border-radius: 10x;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

aside.widget h3.widget-title, aside.widget h3, aside.widget h2.title {
  background-color: #fafafa;
  color: #121212;
  font-family: sans-serif, Arial, serif;
  font-size: 14px;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  line-height: 20px;
  margin: 0;
  padding: 10px 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  -webkit-border-top-left-radius: 10px;
  -moz-border-top-left-radius: 10px;
  border-top-left-radius: 10px;
  -webkit-border-top-right-radius: 10px;
  -moz-border-top-right-radius: 10px;
  border-top-right-radius: 10px;
}

aside.widget .textwidget {
  padding-left: 20px;
  padding-bottom: 10px;
}

aside.widget .textwidget h4 {
  font-size: 13px;
}

aside.widget ul {
  padding: 0 20px;
}

aside.widget ul li:first-child {
  border-top: none;
}

aside.widget ul li {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  background-image: url(/images/heart.png) !important;
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: left center;
  padding: 6px 0 10px 3px;
}

aside.widget ul li a {
  color: #999999;
  padding-left: 20px;
}

#contentsec {
  background: white;
  padding: 30px;
  border-radius: 10px;
}

#contact {
  padding: 20px;
  background-color: #FFF;
  border-radius: 10px;
}

#contact #contactform .label {
  display: none;
}

/* footer */
.before-footer {
  background-color: #1b1f23;
  color: #999999;
  padding: 20px 0 20px;
  text-align: left;
  margin: 0 auto;
  font-size: 13px;
}

.before-footer h3 {
  color: #FFF;
  font-size: 15px;
  text-transform: uppercase;
}

.before-footer img {
  padding: 10px 0 30px;
  max-width: 200px;
}

.before-footer a {
  display: block;
  color: #999999;
  padding: 5px 0;
}

.before-footer ul li {
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  background-image: url(/images/heart.png) !important;
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: left center;
  padding: 6px 0 6px 3px;
}

.before-footer ul li a {
  padding: 0 0 0 20px;
}

footer {
  background-color: #ce0000;
  padding: 20px 0 20px;
  color: #FFF;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
}

footer .container {
  margin: auto;
  max-width: 100%;
}

/*section specific code */
/*styles for page-header partial*/
section.page-title-wrap {
  background-color: #FFF !important;
  padding: 20px 0;
  text-align: center;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  margin-top: -1px;
}

section.page-title-wrap h1.page-title {
  font-size: 30px;
  line-height: 37px;
  color: #1b1f23;
  margin: 0 auto;
  text-shadow: 1px 1px 2px #888;
  text-transform: uppercase;
}

/* hero */
.home-header-wrap {
  background-image: url(/images/idlmj-bkg.jpg);
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
}

.home-header-wrap .header-content-wrap {
  background: rgba(0, 0, 0, 0.64);
  padding: 160px 0 200px;
}

.home-header-wrap .header-content-wrap h1 {
  text-transform: none;
  text-shadow: 1px 1px 18px #666;
  text-align: center;
  color: #FFF;
  line-height: 65px;
  font-size: 50px;
}

.home-header-wrap .header-content-wrap h3 {
  text-align: center;
  margin: auto;
  color: #f3f3f3;
  padding-top: 20px;
}

.home-header-wrap .header-content-wrap .buttons {
  text-align: center;
  margin-top: 45px;
}

.home-header-wrap .header-content-wrap .buttons .btn {
  text-transform: uppercase;
  display: inline-block !important;
  text-align: center;
  margin: 10px;
  padding: 13px 35px 13px 35px;
  font-size: 18px;
  border-color: transparent;
}

.home-header-wrap .header-content-wrap .buttons .red-btn {
  background: #ce0000;
  box-shadow: 0 0 6px #666;
  border-radius: 25px;
}

@media screen and (max-width: 440px) {
  .home-header-wrap .header-content-wrap h1 {
    font-size: 34px;
    line-height: 50px;
  }
}

/*home search only needed when including the search-jobs partial*/
section#home-search {
  text-align: center;
  background-color: #FFF;
  padding: 20px 0 50px;
}

section#home-search h3 {
  color: #1b1f23;
  padding-top: 0;
  font-size: 2em;
  text-shadow: 1px 1px 2px #888;
  margin-bottom: 20px;
  text-transform: uppercase;
}

section#home-search form {
  max-width: 75%;
  text-align: center;
  margin: auto;
  padding: 0;
}

section#home-search input[type="text"] {
  width: 37%;
  margin-right: 1%;
}

section#home-search input[type="submit"] {
  background-color: #ce0000;
  border: 1px solid #ce0000;
  color: #FFF;
  border-radius: 25px;
  font-size: 16px;
  text-transform: uppercase;
  display: inline-block;
  box-shadow: 0 0 6px #666;
}

@media screen and (max-width: 782px) {
  section#home-search form {
    max-width: 90%;
  }
  section#home-search form input[type="text"] {
    width: 100%;
  }
}

/*home listings*/
.jobs {
  padding: 0;
  display: flex;
  max-width: 1170px;
  margin: 0 auto 20px;
  text-align: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.jobs h3 {
  color: #404040;
  padding-bottom: 10px;
  position: relative;
  display: inline-block;
  font-size: 45px;
  text-transform: uppercase;
  text-align: center;
  margin: 30px auto;
  line-height: 50px;
}

.jobs section {
  flex-basis: 98%;
  padding: 1.5% 2.5%;
  margin: 0 0 20px;
  vertical-align: top !important;
  display: inline-block;
  background: #ffffff !important;
  border-radius: 10px;
  text-align: left;
}

.jobs h2.Title {
  padding-bottom: 10px;
  margin-top: 0;
  line-height: 24px;
  height: 26px;
  overflow: hidden;
}

.jobs h2.Title a {
  font-family: 'Raleway', sans-serif;
  color: #1b1f23;
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 700;
}

.jobs a.Company {
  display: block;
  color: #404040;
  font-size: 14px;
}

.jobs .TimePosted {
  display: block;
  color: #999;
  font-size: 12px;
}

.jobs .snippet {
  display: block;
}

.jobs .snippet a.More {
  display: block;
  padding: 10px;
  margin-top: 10px;
  background-color: #ce0000;
  color: #FFFFFF;
  width: 100px;
  text-align: center;
  border-radius: 25px;
}

@media screen and (max-width: 782px) {
  .jobs section {
    flex-basis: 90%;
    justify-content: center;
    margin: 0 auto 20px;
    padding: 5%;
  }
}
